import styles from "./WebMenu.module.scss";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageCard from "./Components/ImageCard";
import img from "../../constants/productImg.json";
import Header from "../../hoc/Header/Header";
import { getImageUrl } from "../../util/imageSring";
import { getCategoryId, getMenuList } from "../../redux/reducers/AppReducer";
import { useNavigate, useParams } from "react-router-dom";
import { setBusinessId, setCategoryId } from "../../redux/actions/AppActions";
import callApi from "../../util/apiCaller";
import ProductCategory from "../../components/ProductCategory/ProductCategory";
import Footer from "../../hoc/Footer/Footer";
import { loader } from "../../hoc/Images";

const DEFAULT_RESTAURANT_ID = "63de9f403cfac3a5f63cb37c";

const WebMenu = () => {
  let sliderRef = useRef(null);
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState({});
  const [banner, setBanner] = useState([]);
  const [step, setStep] = useState(1);
  const [menuId, setMenuId] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { code } = useParams();

  useEffect(() => {
    async function fetchRestaurantInfo() {
      try {
        setLoading(true);
        const restaurantResponse = await callApi(
          `restaurant/${code ? code : DEFAULT_RESTAURANT_ID}`,
          "get"
        );
        if (restaurantResponse && restaurantResponse?.status === "Success") {
          const { restaurant } = restaurantResponse?.data;
          setRestaurant(restaurant);
        } else {
        }
      } catch (e) {}
    }
    fetchRestaurantInfo();
  }, [code]);

  useEffect(() => {
    async function fetchContent() {
      try {
        setLoading(true);
        const banner = await callApi("banner/list", "post", {
          pageNum: 1,
          pageSize: 100,
          filters: {
            restaurant_id: code ? code : DEFAULT_RESTAURANT_ID,
          },
        });

        if (banner && banner?.status === "Success") {
          const { list } = banner?.data;
          setLoading(false);
          let arr = [];
          for (let item of list) {
            if (item?.restaurant_id === code) {
              arr.push(item);
            }
          }
          setBanner(arr);
        } else {
        }
      } catch (e) {}
    }
    fetchContent();
  }, [code]);

  useEffect(() => {
    async function restaurantMenu(code) {
      // dispatch({
      //   type: REQUESTING_STAFF_TO_TABLE,
      //   is_fetching_menu: true,
      // });
      try {
        setLoading(true);
        const res = await callApi(
          `customer/web-menu?restaurant_id=${
            code ? code : DEFAULT_RESTAURANT_ID
          }&agent_id=62d039e95fc41715b11693d5`,
          "get"
        );
        if (res && res.status === "Success") {
          //console.log(res);

          setMenuList(res?.data);
          setLoading(false);
        } else {
        }
      } catch (err) {}
    }
    if (code) {
      restaurantMenu(code);
    }
  }, [code]);

  const handleMenuList = (id) => {
    // navigate(`/product-list`);

    setStep(2);
    setMenuId(id);
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <img src={loader} alt="loader" className="img-fluid" />
        </div>
      )}
      {step === 1 ? (
        <>
          <div className="my_container">
            <Header restaurant={restaurant} />
            <div className={styles.slider_flex}>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,

                  disableOnInteraction: false,
                }}
                // pagination={{
                //   clickable: true,
                // }}
                // navigation={true}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {banner?.map((item) => (
                  <SwiperSlide>
                    {" "}
                    <img
                      src={getImageUrl(item.image_url)}
                      width={"100%"}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <Slider {...settings}>
                {banner?.map((item) => (
                  <img
                    src={getImageUrl(item.image_url)}
                    width={"100%"}
                    alt=""
                  />
                ))}
              </Slider> */}
            </div>

            <div className={styles.category_flex}>
              {menuList?.groupMenuList?.map((item) => (
                <div
                  className={styles.menu_flex}
                  key={item?._id}
                  onClick={() => {
                    handleMenuList(item?._id);
                  }}
                >
                  {item.image_url ? (
                    <img src={getImageUrl(item.image_url)} alt="" />
                  ) : (
                    <img
                      src="images/Loader.png"
                      alt="loader"
                      className="img-fluid"
                    />
                  )}
                  <span className={styles.text_flex}>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <ProductCategory menuId={menuId} menu={menuList} setStep={setStep} />
      ) : null}
    </>
  );
};

export default WebMenu;
