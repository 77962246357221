import callApi from "../../util/apiCaller";
import { APP_TOKEN } from "../../constants/enums/appEnums";

export const SET_RESTAURANT_ID = "SET_RESTAURANT_ID";
export const SET_BUSINESS_ID = "SET_BUSINESS_ID";

export const LOADING_MENU = "LOADING_MENU";
export const LOAD_MENU = "LOAD_MENU";

export const PLACING_ORDER = "PLACING_ORDER";
export const PLACE_ORDER = "PLACE_ORDER";

export const UPDATING_ORDER = "UPDATING_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";

export const REQUEST_STAFF_TO_TABLE = "REQUEST_STAFF_TO_TABLE";
export const REQUESTING_STAFF_TO_TABLE = "REQUESTING_STAFF_TO_TABLE";
export const MENU_LIST = "MENU_LIST";
export const CATEGORY_ID = "CATEGORY_ID";

export function setRestaurantId(restaurant_id) {
  return (dispatch) => {
    dispatch({
      type: SET_RESTAURANT_ID,
      restaurant_id,
    });
  };
}

export function setBusinessId(business_id) {
  return (dispatch) => {
    dispatch({
      type: SET_BUSINESS_ID,
      business_id,
    });
  };
}
export function setMenuList(menulist) {
  return (dispatch) => {
    dispatch({
      type: MENU_LIST,
      menulist: menulist,
    });
  };
}
export function setCategoryId(categoryId) {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_ID,
      categoryId: categoryId,
    });
  };
}

export function fetchCustomerMenu(business_id, restaurant_id, app_password) {
  return async (dispatch) => {
    dispatch({
      type: LOADING_MENU,
      is_fetching_menu: true,
    });
    try {
      const res = await callApi(
        `customer/web-menu?restaurant_id=${restaurant_id}&business_id=${business_id}&app_password=${app_password}`,
        "get"
      );
      if (res && res.status === "Success") {
        const {
          groupMenuList,
          categoryMenuList,
          tableList,
          eventList,
          dishList,
        } = res.data;
        dispatch({
          type: LOAD_MENU,
          is_fetching_menu: false,
          group_menu: groupMenuList,
          category_menu: categoryMenuList,
          dish_list: dishList,
        });
      } else {
        dispatch({
          type: LOADING_MENU,
          is_fetching_menu: false,
        });
      }
    } catch (err) {
      dispatch({
        type: LOADING_MENU,
        is_fetching_menu: false,
      });
    }
  };
}

export function placeNewOrder(restaurant_id, order_details) {
  return async (dispatch) => {
    dispatch({
      type: PLACING_ORDER,
      is_fetching_menu: true,
    });
    try {
      const res = await callApi(`custmer/order/new-order`, "post", {});
      if (res && res.status === "Success") {
        const { token } = res.data;
        dispatch({
          type: PLACE_ORDER,
          is_fetching_menu: false,
        });
      } else {
        dispatch({
          type: PLACING_ORDER,
          is_fetching_menu: false,
        });
      }
    } catch (err) {
      dispatch({
        type: PLACING_ORDER,
        is_fetching_menu: false,
      });
    }
  };
}

export function updateOrder(order_id, order_details) {
  return async (dispatch) => {
    dispatch({
      type: UPDATING_ORDER,
      is_fetching_menu: true,
    });
    try {
      const res = await callApi(
        `customer/order/${order_id}/update-order`,
        "post",
        {}
      );
      if (res && res.status === "Success") {
        const { token } = res.data;
        dispatch({
          type: UPDATE_ORDER,
          is_fetching_menu: false,
        });
      } else {
        dispatch({
          type: UPDATING_ORDER,
          is_fetching_menu: false,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATING_ORDER,
        is_fetching_menu: false,
      });
    }
  };
}

export function requestStaffToTable(restaurant_id, order_id) {
  return async (dispatch) => {
    dispatch({
      type: REQUESTING_STAFF_TO_TABLE,
      is_fetching_menu: true,
    });
    try {
      const res = await callApi(
        `customer/web-request-staff?restaurant_id=${restaurant_id}&order_id=${order_id}`,
        "get"
      );
      if (res && res.status === "Success") {
        const { token } = res.data;
        dispatch({
          type: REQUEST_STAFF_TO_TABLE,
          is_fetching_menu: false,
        });
      } else {
        dispatch({
          type: REQUESTING_STAFF_TO_TABLE,
          is_fetching_menu: false,
        });
      }
    } catch (err) {
      dispatch({
        type: REQUESTING_STAFF_TO_TABLE,
        is_fetching_menu: false,
      });
    }
  };
}
// export function restaurantMenu() {
//   return async (dispatch) => {
//     // dispatch({62d02e8430ec4b13a3766e35
//     //   type: REQUESTING_STAFF_TO_TABLE,
//     //   is_fetching_menu: true,
//     //62d02e8430ec4b13a3766e31
//     // });
//     try {
//       const res = await callApi(
//         `customer/web-menu?restaurant_id=62d02e8430ec4b13a3766e35&agent_id=62d039e95fc41715b11693d5`,
//         "get"
//       );
//       if (res && res.status === "Success") {
//         //console.log(res);
//         dispatch(setMenuList(res.data));
//       } else {
//       }
//     } catch (err) {}
//   };
// }
