import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const ImageLoading = () => {
  const navigate = useNavigate();
  // const { code } = useParams();
  return (
    <div className="loadergifdiv">
      <img
        src="images/windmill_img3.png"
        alt="loader"
        width={"90%"}
        style={{ cursor: "pointer", paddingLeft: "1.8em" }}
        className="img-fluid"
        onClick={() => navigate(`/menu/63de9f403cfac3a5f63cb37c`)}
      />
    </div>
  );
};

export default ImageLoading;
