import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Card } from "react-bootstrap";
import styles from "./ProductCategory.module.scss";
//import category from "../../constants/productCategory.json";
import ProductModal from "../ProductModal/ProductModal";
import subCategory from "../../constants/subCategory.json";
import img from "../../constants/productImg.json";
import RadioButton from "../RadioButton/RadioButton";
import SubCategory from "../SubCategory/RadioButton";
import Footer from "../../hoc/Footer/Footer";
import { useSelector } from "react-redux";
import { getCategoryId, getMenuList } from "../../redux/reducers/AppReducer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getImageUrl } from "../../util/imageSring";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { home, loader } from "../../hoc/Images";

const ProductCategory = ({ menuId, menu, setStep }) => {
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [staticImg, setStaticImg] = useState(null);
  //const menuId = useSelector((state) => getCategoryId(state));
  const [product, setProduct] = useState([]);
  const [cardIndex, setCardIndex] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [groupList, setGroupList] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [imageList, setImageList] = useState([]);
  //const menu = useSelector((state) => getMenuList(state));
  useEffect(() => {
    setLoading(true);
    if (menuId && menu) {
      let group = [...menu?.groupMenuList];
      for (let item of group) {
        if (item?._id === menuId) {
          setGroupList(item?.name);
        }
      }
      let arr = [...menu?.categoryMenuList];
      let dishList = [...menu?.dishList];
      console.log(dishList);
      let res = [];
      let dishImg = [];
      let id = "";
      for (let item of arr) {
        if (item?.group_menu_id === menuId) {
          res.push(item);
        }
      }
      let filt = dishList.filter((item) => item.is_active === true);
      for (let item of filt) {
        if (item?.menu_category_id === res?.[0]?._id) {
          dishImg.push(item);
          setLoading(false);
        }
      }
      setCategoryName(res?.[0]?.name);
      setStaticImg(res?.[0]);

      function compareFunction(a, b) {
        const itemPosition =
          a.screenPosition === b.screenPosition
            ? a.tilePosition === b.tilePosition
              ? a.createdAt - b.createdAt
              : a.tilePosition - b.tilePosition
            : a.screenPosition - b.screenPosition;
        return itemPosition;
      }

      setProduct(res);

      setImageList(dishImg?.sort(compareFunction));

      // setImageList(dishImg?.sort(compareFunction));
    }
  }, [menuId, menu]);

  const productCategoryHandler = (keyName, keyValue) => {
    let arr = [...menu?.categoryMenuList?.sort(compareFunction)];
    function compareFunction(a, b) {
      return a.position - b.position;
    }
    let dishItem = "";
    let dishImg = [];
    let dishList = [...menu?.dishList];
    let filt = dishList.filter((item) => item.is_active === true);
    if (keyName === "product_list") {
      let res = [];

      let group = [...menu?.groupMenuList];
      for (let item of group) {
        if (item?._id === keyValue) {
          setGroupList(item?.name);
        }
      }
      for (let item of arr) {
        if (item?.group_menu_id === keyValue) {
          res.push(item);
        }
      }

      setProduct(res);
      setStaticImg(res?.[0]);
      for (let item of filt) {
        if (item?.menu_category_id === res?.[0]?._id) {
          dishImg.push(item);
        }
      }

      setCategoryName(res?.[0]?.name);

      setImageList(dishImg);
    }
    if (keyName === "category_list") {
      for (let item of arr) {
        if (item?._id === keyValue) {
          setCategoryName(item?.name);
          setStaticImg(item);
        }
      }
      for (let item of dishList) {
        if (item?.menu_category_id === keyValue) {
          dishImg.push(item);
        }
      }

      setImageList(dishImg?.sort(compareFunction));
    }
  };
  console.log(imageList);

  const TestimonialCard = ({ img, id, name, description, actualPrice }) => {
    return (
      <Card style={{ margin: "0 0.5em" }}>
        <div className={`${styles.img_flex}`}>
          <img src={img} />
          <div
            className={styles.close_btn}
            onClick={() => setDropdownActive(false)}
          >
            <CloseIcon
              style={{
                fontSize: "2.5em",

                // boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.25)",
              }}
            />
          </div>
        </div>
        <Card.Body>
          <div className={styles.cart_text}>
            <div className={styles.cart_menu}>
              <div className={styles.cart_heading}>{name}</div>
              <div className={styles.cart_desc}>{description}</div>
              {/* <div className={styles.action}>
              <button onClick={decreaseItemHandler}>+</button>
              <button onClick={increaseItemHandler}>-</button>
              <span
                className={styles.add_btn}
                onClick={() => {
                  navigate("/cart");
                }}
              >
                ADD
              </span>
            </div> */}
            </div>
            <div className={styles.price_flex}>
              <span>
                <CurrencyRupeeIcon style={{ fontSize: "1.2em" }} />{" "}
                {actualPrice}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <img src={loader} alt="loader" className="img-fluid" />
        </div>
      )}
      <div className="my_container">
        {dropdownActive === true && (
          <div
            className={styles.dropdown_box}
            //onMouseOver={() => setDropdownActive(true)}
            //onMouseOut={() => setDropdownActive(false)}
          >
            <Swiper
              //loop={true}
              slidesPerView={1}
              spaceBetween={20}
              navigation={false}
              initialSlide={cardIndex}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination]}
              className={styles.mySwiper}
              style={{
                "--swiper-pagination-top": -8,
                "--swiper-pagination-bottom": "auto",
                // "--swiper-pagination-color": "#red",
                // "--swiper-pagination-bullet-inactive-color": "#999999",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                // "--swiper-pagination-bullet-size": "25px",
                // "--swiper-pagination-bullet-horizontal-gap": "15px",
              }}
            >
              {imageList?.map((item, i) => (
                <SwiperSlide key={item?._id}>
                  <TestimonialCard
                    img={getImageUrl(item.image_url)}
                    name={item?.name}
                    description={item?.description}
                    id={item?._id}
                    actualPrice={item.actual_price}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        <div className={styles.header_flex}>
          <Link
            className={styles.home_icon}
            onClick={() => {
              setStep(1);
            }}
          >
            <img src={home} alt="" />
          </Link>
          <div className={styles.product_flex}>
            {menu?.groupMenuList?.map((item, i) => (
              <>
                <RadioButton
                  checked={item.name === groupList}
                  value={item._id}
                  labelId={item.name}
                  label={item.name}
                  keyName={"product_list"}
                  productCategoryHandler={productCategoryHandler}
                />

                {menu?.groupMenuList?.length - 1 !== i ? <span>|</span> : null}
              </>
            ))}
          </div>
          <Link className={styles.cart_icon}>
            {/* <img src="images/Group.svg" alt="" /> */}
          </Link>
        </div>
        <div className={styles.product_sub_category}>
          {product?.map((item, i) => (
            <div key={i}>
              <SubCategory
                checked={item.name === categoryName}
                value={item._id}
                labelId={item.name}
                label={item.name}
                keyName={"category_list"}
                productCategoryHandler={productCategoryHandler}
              />
            </div>
          ))}
        </div>
        <div className={styles.category_flex}>
          <div className={styles.menu_flex}>
            {staticImg?.image_url ? (
              <img src={getImageUrl(staticImg?.image_url)} alt="" />
            ) : (
              <img src={loader} alt="loader" className="img-fluid" />
            )}
            <span className={styles.text_static}>{staticImg?.name}</span>
          </div>

          {imageList?.map((item, i) => (
            <>
              {i === 0 ? (
                item?.row_span_tile === 2 ? (
                  <div
                    style={{ gridColumn: "2 / span 2" }}
                    className={styles.menu_flex}
                    key={item._id}
                    onClick={() => {
                      setCardIndex(i);
                      setDropdownActive(true);
                    }}
                  >
                    {item.image_url ? (
                      <img src={getImageUrl(item.image_url)} alt="" />
                    ) : (
                      <img src={loader} alt="loader" className="img-fluid" />
                    )}
                    <span className={styles.text_flex}>{item.name}</span>
                  </div>
                ) : (
                  <div
                    className={styles.menu_flex}
                    key={item._id}
                    onClick={() => {
                      setCardIndex(i);
                      setDropdownActive(true);
                    }}
                  >
                    {item.image_url ? (
                      <img src={getImageUrl(item.image_url)} alt="" />
                    ) : (
                      <img src={loader} alt="loader" className="img-fluid" />
                    )}
                    <span className={styles.text_flex}>{item.name}</span>
                  </div>
                )
              ) : (
                <div
                  className={styles.menu_flex}
                  key={item._id}
                  onClick={() => {
                    setCardIndex(i);
                    setDropdownActive(true);
                  }}
                >
                  {item.image_url ? (
                    <img src={getImageUrl(item.image_url)} alt="" />
                  ) : (
                    <img src={loader} alt="loader" className="img-fluid" />
                  )}
                  <span className={styles.text_flex}>{item.name}</span>
                </div>
              )}
            </>
          ))}
        </div>
        <Footer />
      </div>
      {/* <ProductModal
        className="white_theme"
        imageList={imageList}
        show={modal}
        onHide={() => setModal(false)}
      /> */}
    </>
  );
};

export default ProductCategory;
