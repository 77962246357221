import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

// componentWillMount

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
  // other store enhancers if any
);

export default store;
