import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";
import "./modal.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImageUrl } from "../../util/imageSring";
import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

function ProductModal(props) {
  const [loading, setLoading] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {loading && (
        <div className="loadergifdiv">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <Modal
        {...props}
        className={`define_modal ${props?.className}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <div class="modal-dialog"> */}
        {/* <div class="modal-content"> */}

        <Modal.Body>
          <div className="my_container">
            <Slider {...settings}>
              {props?.imageList?.map((item) => (
                // <Card>
                <div className="define_modal_wrapper">
                  <img src={getImageUrl(item?.image_url)} alt="" />
                </div>
                //   <Card.Body>
                //     <Card.Text></Card.Text>
                //   </Card.Body>
                // </Card>
              ))}
            </Slider>
          </div>
        </Modal.Body>
        <div class="modal-footer">
          {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {props.onHide()}}>Close</button> */}
        </div>
        {/* </div> */}
        {/* </div> */}
      </Modal>
    </>
  );
}

export default ProductModal;
