const _cloudfrontHost = "https://d2p7jswxgh09z9.cloudfront.net/";
const _awsS3HostOne = "https://windmill-images.s3.ap-south-1.amazonaws.com/";
const _awsS3HostTwo = "https://windmill-assets.s3.ap-south-1.amazonaws.com/";
const _imageKitHost = "https://d3eavn3p0apacm.cloudfront.net/";

export function getImageUrl(url) {
  if (!url || url === "") {
    return "No Image Key";
  }

  if (url.includes(_cloudfrontHost)) {
    return url.replace(_cloudfrontHost, _imageKitHost);
  }

  if (url.includes(_awsS3HostOne)) {
    return url.replace(_awsS3HostOne, _imageKitHost);
  }

  if (url.includes(_awsS3HostTwo)) {
    return url.replace(_awsS3HostTwo, _imageKitHost);
  }

  if (url.includes(_imageKitHost)) {
    return url;
  }

  return url === "NOT_SET" ? url : _imageKitHost + url;
}
