//import MenuCard from "./pages/MenuCard/MenuCard";
import { useEffect } from "react";
import ProductCategory from "./components/ProductCategory/ProductCategory";
//import MenuCardList from "./pages/MenuCardList/MenuCardList";
import WebMenu from "./pages/WebMenu/WebMenu";
import { useDispatch } from "react-redux";
import { restaurantMenu } from "./redux/actions/AppActions";
import { RESTAURANT_ID } from "./constants/enums/appEnums";
import { setBusinessId, setCategoryId } from "./redux/actions/AppActions";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-bootstrap";
import ImageLoading from "./components/ImageLoading";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // window.localStorage.setItem(RESTAURANT_ID, "62d02e8430ec4b13a3766e35");
  // const restaurantId = window.localStorage.getItem(RESTAURANT_ID);

  return (
    <>
      <ToastContainer />
      {/* <WebMenu /> */}
      <Routes>
        <Route path="/" element={<ImageLoading />} />
        <Route path="/menu/:code" element={<WebMenu />} />
        {/* <Route path="/product-list" element={<ProductCategory />} /> */}
        {/* <Route exact path="/" element={<Navigate to="/product-list" />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="/" element={<Navigate to="/menu-list" />} /> */}
      </Routes>
    </>
  );
}
export default App;
