import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import RadioButton from "../../components/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryId, getMenuList } from "../../redux/reducers/AppReducer";
import { home } from "../Images";
// import {
//   fetchCustomerMenu,
//   setBusinessId,
//   setRestaurantId,
// } from "../redux/actions/AppActions";
// import { getGroupMenu } from "../redux/reducers/AppReducer";

const Header = ({ restaurant }) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const categoryId = useSelector((state) => getCategoryId(state));
  const [groupList, setGroupList] = useState("");
  const menu = useSelector((state) => getMenuList(state));
  // let [searchParams, setSearchParams] = useSearchParams();

  // let restaurant_id = searchParams.get("restaurant_id");
  // let business_id = searchParams.get("business_id");
  // let app_password = searchParams.get("app_password");

  // const group_menu = useSelector((state) => getGroupMenu(state));

  // useEffect(() => {
  //   dispatch(setRestaurantId(restaurant_id));
  //   dispatch(setBusinessId(business_id));
  //   // todo persist state
  //   dispatch(fetchCustomerMenu(business_id, restaurant_id, app_password));
  // }, [restaurant_id, business_id]);
  useEffect(() => {
    if (categoryId && menu) {
      let arr = [...menu?.categoryMenuList];

      let res = [];

      for (let item of arr) {
        if (item?.group_menu_id === categoryId) {
          res.push(item);
        }
      }
      // id = res?.[0]?._id;

      setProduct(res);
    }
  }, [categoryId, menu]);
  const productCategoryHandler = (keyName, keyValue) => {
    if (keyName === "product_list") {
      let res = [];
      let arr = [...menu?.categoryMenuList];
      setGroupList(keyValue);
      for (let item of arr) {
        if (item?.group_menu_id === keyValue) {
          res.push(item);
        }
      }
      setProduct(res);
    }
  };

  return (
    // <div className="my_container">
    <div className={styles.header_flex}>
      <Link className={styles.home_icon} to={"/menu-list"}>
        <img src={home} alt="" />
      </Link>
      {/* <div className={styles.product_flex}>
          {menu?.groupMenuList?.map((item, i) => (
            <>
              <RadioButton
                checked={item.name === groupList}
                value={item._id}
                labelId={item.name}
                label={item.name}
                keyName={"product_list"}
                productCategoryHandler={productCategoryHandler}
              />

              {menu?.groupMenuList?.length - 1 !== i ? <span>|</span> : null}
            </>
          ))}
        </div> */}
      <div className={styles.windmail_text}>
        <h5>{restaurant?.name}</h5>
      </div>
      <Link className={styles.cart_icon}>
        {/* <img src="images/Group.svg" alt="" /> */}
      </Link>
    </div>
    // </div>
  );
};

export default Header;
