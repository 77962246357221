//import Action
import {
  SET_RESTAURANT_ID,
  SET_BUSINESS_ID,
  LOADING_MENU,
  LOAD_MENU,
  PLACING_ORDER,
  PLACE_ORDER,
  UPDATE_ORDER,
  UPDATING_ORDER,
  REQUESTING_STAFF_TO_TABLE,
  REQUEST_STAFF_TO_TABLE,
  MENU_LIST,
  CATEGORY_ID,
} from "../actions/AppActions";

const initialState = {
  restaurant_id: "",
  business_id: "",
  is_fetching_menu: false,
  group_menu: [],
  category_menu: [],
  dish_list: [],
  menulist: {},
  categoryId: "",
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANT_ID:
      return {
        ...state,
        restaurant_id: action.restaurant_id,
      };

    case SET_BUSINESS_ID:
      return {
        ...state,
        business_id: action.business_id,
      };

    case LOADING_MENU:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case LOAD_MENU:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
        group_menu: action.group_menu,
        category_menu: action.category_menu,
        dish_list: action.dish_list,
      };

    case PLACING_ORDER:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case PLACE_ORDER:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case UPDATING_ORDER:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case REQUEST_STAFF_TO_TABLE:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };

    case REQUESTING_STAFF_TO_TABLE:
      return {
        ...state,
        is_fetching_menu: action.is_fetching_menu,
      };
    case MENU_LIST:
      return {
        ...state,
        menulist: action.menulist,
      };
    case CATEGORY_ID:
      return {
        ...state,
        categoryId: action.categoryId,
      };
    default:
      return state;
  }
};

/* Selectors */
export const getMenuLoadingState = (state) => state.app.is_fetching_menu;
export const getRestaurantId = (state) => state.app.restaurant_id;
export const getBusinessId = (state) => state.app.business_id;
export const getGroupMenu = (state) => state.app.group_menu;
export const getCategoryMenu = (state) => state.app.category_menu;
export const getDishList = (state) => state.app.dish_list;
export const getMenuList = (state) => state.app.menulist;
export const getCategoryId = (state) => state.app.categoryId;

//Export Reducer
export default AppReducer;
